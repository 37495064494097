.menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 60px;
  background: #9b44f2;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 21;
  cursor: pointer;
}
.menu .burgerImg {
  width: 50px;
  height: 50px;
}

.burgerImg.unrotate {
  animation-name: unrotate;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.burgerImg.rotate {
  animation-name: rotateIn;
  animation-duration: 1s;
  animation-fill-mode: both;
} 

/*animations frames*/
@keyframes rotateIn {
  0% {
    transform-origin: center;
    transform: rotate(0);
    opacity: 0;
  }
  100% {
    transform-origin: center;
    transform: rotate(90deg);
    opacity: 1;
  }
}
@keyframes unrotate {
  0% {
    transform-origin: center;
    transform: rotate(0, 0, 1, -100deg);
    opacity: 0;
  }
  100% {
    transform-origin: center;
    transform: none;
    opacity: 1;
  }
} 

@media (max-width: 1200px) {
  .menu {
    height: 50px;
    width: 100vw;
    justify-content: flex-end;
  }
  .burgerImg {
    margin-right: 20px;
  }
}

@media (max-width: 450px) {
  .menu {
    height: 40px;
  }
  .menu .burgerImg {
    width: 40px;
    height: 40px;
  }
  .burgerImg {
    margin-right: 10px;
  }
}
@media (max-height: 450px) {
  .menu {
    height: 40px;
  }
  .menu .burgerImg {
    width: 40px;
    height: 40px;
  }
  .burgerImg {
    margin-right: 10px;
  }
}
@media (max-height: 375px){
  .menu {
    height: 30px;
  }
  .menu .burgerImg {
    width: 30px;
    height: 30px;
  }

}
