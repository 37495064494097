.bannerTop {
  width: 100vw;
  height: 100vh;
}
.waveWrapper {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  justify-content: center;
  background: url("../images/bgNew.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  z-index: 20;
}
.waveWrapperInner {
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: 100%;
  bottom: -1px;
  background-image: linear-gradient(
    to top,
    rgba(134, 55, 123, 0.4) 20%,
    rgba(39, 39, 60, 0.4) 80%
  );
}
.bgTop {
  z-index: 15;
  opacity: 0.5;
}
.bgMiddle {
  z-index: 10;
  opacity: 0.75;
}
.bgBottom {
  z-index: 5;
}
.wave {
  position: absolute;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
}
.waveTop {
  background-size: 50% 100px;
  background-image: url("../images/banner/wave-top.png");
}
.waveAnimation .waveTop {
  animation: move-wave 3s;
  -webkit-animation: move-wave 3s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.waveMiddle {
  background-size: 50% 120px;
  background-image: url("../images/banner/wave-mid.png");
}
.waveAnimation .waveMiddle {
  animation: move_wave 10s linear infinite;
}
.waveBottom {
  background-size: 50% 100px;
  background-image: url("../images/banner/wave-bot.png");
}
.waveAnimation .waveBottom {
  animation: move_wave 15s linear infinite;
}
.banner .container {
  z-index: 21;
  color: var(--contrast-color);
  position: absolute;
  top: 40px;
  left: 100px;
  width: 40vw;
}
.banner .container.move {
  margin-left: 30vw;
}
.banner .container.unmove {
  margin-left: 0;
}
h1 {
  font-size: 2.5rem;
}
.bannerText {
  margin-top: 60px;
}
.banner h2 {
  margin: 40px 0;
}
.banner-descr {
  line-height: 1.5;
  font-size: 1.5rem;
}
.bannerLogo img {
  height: 90px;
}

@media (max-width: 1200px) {
  .bannerTop {
    height: 90vh;
  }
  .waveWrapper {
    width: 100%;
    height: 90vh;
    bottom: auto;
  }
  .banner .container {
    width: 100vw;
    max-width: 100vw;
    top: 60px;
    left: 20px;
  }
  .bannerLogo {
    max-width: 80vw;
  }
}
@media (max-width: 1024px) and (min-height: 1024px) {
  .bannerTop {
    height: 50vh;
  }
  .waveWrapper {
    height: 50vh;
  }
}
@media (max-width: 1024px) and (max-height: 660px) {
  .bannerLogo {
    max-width: 50vw;
  }
  .bannerTop {
    height: 100vh;
  }
  .waveWrapper {
    height: 100vh;
  }
}
@media (max-width: 800px) {
  .bannerTop {
    height: 70vh;
  }
  .waveWrapper {
    height: 70vh;
  }
  .banner h2 {
    margin: 30px 20px 30px 0px;
  }
  .banner-descr {
    margin-right: 20px;
  }
}
@media (max-width: 600px) {
  .bannerLogo img {
    height: 65px;
  }
}

@media (max-width: 450px) {
  .bannerTop {
    height: 100vh;
  }

  .waveWrapper {
    height: 100vh;
  }
  .bannerLogo {
    max-width: 100vw;
  }
  .banner .container {
    top: 50px;
    left: 0px;
  }
  .bannerText {
    margin-top: 20px;
  }
  .banner h2 {
    margin: 10px 0;
  }
  .banner-descr {
    margin-right: 0;
  }
}

@media (max-width: 450px) and (min-height: 730px) {
  .bannerTop {
    height: 80vh;
  }
  .bannerLogo img {
    height: 65px;
  }
  .waveWrapper {
    height: 80vh;
  }
}

@media (max-height: 450px) {
  .bannerTop {
    height: 100vh;
  }
  .bannerLogo img {
    height: 65px;
  }
  .waveWrapper {
    height: 100vh;
  }
  .banner .container {
    top: 50px;
    left: 0px;
  }
  .bannerText {
    margin-top: 20px;
  }
  .banner h2 {
    margin: 10px 0;
  }
  .banner-descr {
    margin-right: 0;
  }
}

@media (max-height: 380px) {
  h1 {
    font-size: 2rem;
  }
  .banner h2 {
    font-size: 1.5rem;
  }
  .banner-descr {
    font-size: 1.1rem;
  }
  .banner .container {
    top: 30px;
  }
  .bannerLogo {
    max-width: 40vw;
  }
}
@media (max-width: 359px) {
  .bannerLogo img {
    height: 50px;
  }
}

@media (max-height: 450px) and (max-width: 640px) {
  .bannerLogo img {
    height: 45px;
  }
}
