@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.whywe {
  background: linear-gradient(
    to right bottom,
    #d9b8f9,
    #d6baef,
    #d2bce5,
    #cebddc,
    #cabfd2,
    #c9c4d3,
    #c9c9d3,
    #cccdd2,
    #d3d6db,
    #dadfe3,
    #e1e8eb,
    #e9f1f2
  );
}
.whywe .container.move {
  margin-left: 35vw;
}
.whywe .container.unmove {
  margin-left: 0 auto;
}
.whywe h2 {
  color: var(--main-color);
}
.whywe .list {
  list-style: none;
  padding: 0;
  margin: 40px 0;
}
.whywe.active .list li {
  background: var(--contrast-color);
  padding: 7px 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  border-left: 20px solid #44f2f2;
  box-shadow: 2px -2px 5px 0 rgba(0, 0, 0, 0.1),
    -2px -2px 5px 0 rgba(0, 0, 0, 0.1), 2px 2px 5px 0 rgba(0, 0, 0, 0.1),
    -2px 2px 5px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s all linear;
  animation-name: pulse;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.whywe.whywe.active .list li:nth-child(1) {
  animation-delay: 0.25s;
}
.whywe.whywe.active .list li:nth-child(2) {
  animation-delay: 1s;
}
.whywe.whywe.active .list li:nth-child(3) {
  animation-delay: 2s;
}
.whywe.whywe.active .list li:nth-child(4) {
  animation-delay: 3s;
}

@media (max-width: 450px) {
  .whywe .list {
    margin: 20px 0;
  }
}

@media (max-height: 450px) {
  .whywe .list {
    margin: 20px 0;
  }
}
