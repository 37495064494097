@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
.howToStart {
  position: relative;
}
.howToStart .container.move {
  margin-left: 35vw;
}
.howToStart .container.unmove {
  margin-left: 0 auto;
}

.howToStart h2 {
  color: var(--main-color);
}
.howToStart .list {
  list-style: none;
  padding: 0;
  margin: 40px 0;
  display: flex;
  column-gap: 20px;
}

.howToStart.active .list li {
  background: var(--contrast-color);
  padding: 20px 10px;
  border-radius: 5px;
  border-top: 20px solid #44f2f2;
  box-shadow: 2px -2px 5px 0 rgba(0, 0, 0, 0.1),
    -2px -2px 5px 0 rgba(0, 0, 0, 0.1), 2px 2px 5px 0 rgba(0, 0, 0, 0.1),
    -2px 2px 5px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s all linear;
  animation-name: flipInY;
  backface-visibility: visible !important;
  animation-duration: 1s;
  animation-fill-mode: both;
  width: 33%;
}

.howToStart.active .list li:nth-child(1) {
  animation-delay: 0.25s;
}
.howToStart.active .list li:nth-child(2) {
  animation-delay: 1s;
}
.howToStart.active .list li:nth-child(3) {
  animation-delay: 2s;
}

@media (max-width: 700px) {
  .howToStart .list {
    flex-wrap: wrap;
    row-gap: 20px;
    justify-content: space-around;
  }
  .howToStart.active .list li {
    width: 48%;
  }
}
@media (max-width: 450px) {
  .howToStart .container {
    padding-bottom: 50px;
  }
  .howToStart.active .list li {
    width: 100%;
  }
  .howToStart .list {
    margin: 20px 0;
  }
}

@media (max-height: 450px) {
  .howToStart .container {
    padding-bottom: 50px;
  }
  .howToStart.active .list li {
    width: 100%;
  }
  .howToStart .list {
    margin: 20px 0;
  }
}
