@keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.second h2 {
  color: var(--main-color);
}
.second .container {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: 1fr;
  column-gap: 40px;
}
.second .imgBlock {
  position: relative;
}

.second .textImage {
  width: 90%;
  margin: 0 auto;
}
.second .image {
  position: absolute;
  top: 50px;
  left: 202px;
  width: 30%;
  animation: 20s linear 0s normal none infinite running rot;
}
.second .blocks {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
}

.second .container.move {
  margin-left: 35vw;
}
.second .container.unmove {
  margin-left: 0 auto;
}

.second .imgCheckPhone,
.second .imgCheckEmail {
  width: 18px;
  position: absolute;
  bottom: 10px;
  right: 20px;
}
.second .imgCheckPhone {
  bottom: 16px;
}
.PhoneInputInput {
  padding-top: 5px;
  padding-bottom: 5px;
}
.PhoneInput--focus input {
  outline: 0 !important;
  outline-offset: 0 !important;
  border: 1px solid var(--second-accent-color);
}
.PhoneInput input:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  border: 1px solid var(--second-accent-color);
}
.second .form-phone,
.second .form-email {
  position: relative;
}
.contactForm label,
.contactForm input {
  font-size: 14px;
}
.contactForm label {
  font-weight: bold;
  color: var(--main-color);
}
.contactForm > div {
  margin-bottom: 15px;
}
.contactForm > div:last-child {
  margin-bottom: 0;
}
.contactForm > div:last-child {
  margin-top: 30px;
}
.contactForm .btn-primary {
  background: #5a30f2;
  border: none;
}
.contactForm .btn-primary:hover {
  background: var(--accent-color);
}

@media (max-width: 1200px) {
  .second .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    row-gap: 60px;
  }
  .second .blocks {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 1fr;
    row-gap: 10px;
  }
  .second .imgBlock {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .second .textImage {
    width: 70%;
  }
  .second .image {
    top: 70px;
    left: 39%;
    width: 22%;
  }
  .contactForm {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
  }
  .contactForm > div {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0;
  }
  .contactForm > div:last-child {
    margin-top: 0px;
  }
  .cF-button button {
    margin-bottom: 6px;
  }
}

@media (max-width: 800px) {
  .second .textImage {
    width: 100%;
  }
  .second .image {
    top: 80px;
    width: 30%;
    left: 35%;
  }
}
@media (max-width: 600px) {
  .second .image {
    top: 50px;
  }
}
@media (max-width: 450px) {
  .contactForm > div {
    width: 100%;
  }
  .second .image {
    width: 30%;
    left: 35%;
    top: 22%;
  }
}

@media (max-height: 450px) {
  .contactForm > div {
    width: 100%;
  }
  .second .image {
    width: 30%;
    left: 35%;
    top: 22%;
  }
}

@media (max-height: 450px) and (min-width: 750px) {
  .second .image {
    width: 25%;
    left: 38%;
    top: 16%;
  }
}
