.nav {
  display: flex;
  flex-direction: column;
  z-index: 25;
  padding: 40px 20px;
  animation-name: slideInLeft;
  animation-duration: 2s;
  animation-fill-mode: both;
  position: absolute;
  background-image: linear-gradient(
    to bottom,
    #2d1973,
    #532aa2,
    #6732ba,
    #7d3ad2,
    #e9f1f2
  );
  height: 100vh;
  width: 30vw;
  position: fixed;
  top: 0;
  left: 0;
}
.nav.show {
  animation-name: slideInLeft;
  animation-duration: 2s;
  animation-fill-mode: both;
}
.nav.hidden {
  animation-name: slideOutLeft;
  animation-duration: 1.5s;
  animation-fill-mode: both;
}
.closeForm {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

.nav-link {
  width: 80%;
  color: var(--contrast-color);
  padding: 10px 20px;
}

.nav-link:hover {
  background: var(--contrast-color);
  color: var(--main-color);
}

@keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@media (max-width: 1200px) {
  .nav {
    width: 100vw;
    right: 0;
    max-width: 100vw;
  }
  .nav.show {
    animation-name: slideInRight;
    animation-duration: 2s;
    animation-fill-mode: both;
  }
  .nav.hidden {
    animation-name: slideOutRight;
    animation-duration: 1.5s;
    animation-fill-mode: both;
  }
  .nav-link{
    font-size: 1.5rem;
  }
}
