@import "bootstrap/dist/css/bootstrap.min.css";
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700&display=swap");
html {
  --main-color: #110a40;
  --contrast-color: #e9f1f2;
  --accent-color: #9b44f2;
  scroll-behavior: smooth;
  --second-accent-color: #4f5873;
}
* {
  box-sizing: border-box;
}
html,
body {
  overflow-x: hidden;
  font-family: "Jost", sans-serif;
  font-size: 19px;
}
.container {
  margin: 0 auto;
  padding: 0;
}
a,
a:hover {
  text-decoration: none;
}
h1,
h2,
h3 {
  text-transform: uppercase;
}
h2,
h3,
h4,
h5 {
  font-weight: bold;
  color: var(--contrast-color);
}
h2 {
  font-size: 1.8rem;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    visibility: visible;
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    visibility: visible;
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }
  100% {
    visibility: hidden;

    transform: translateX(100%);
  }
}

@keyframes move_wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }
  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}

.common__section .container {
  width: 1120px;
  margin: 0 auto;
  padding: 60px 0;
}

.container.move {
  animation-name: slideInLeft;
  animation-duration: 2s;
  animation-fill-mode: both;
}
.container.unmove {
  animation-name: slideInRight;
  animation-duration: 2s;
  animation-fill-mode: both;
}
@media (min-width: 1201px) and (max-width: 1365px) {
  .common__section .container {
    width: 1100px;
  }
}
@media (max-width: 1200px) {
  .container,
  .common__section .container {
    width: 100vw;
    max-width: 100vw;
    padding: 40px 10px;
  }
}
@media (max-width: 450px) {
  html,
  body {
    font-size: 14px;
  }
  .container,
  .common__section .container {
    padding: 10px;
  }
}
@media (max-height: 450px) {
  html,
  body {
    font-size: 14px;
  }
  .container,
  .common__section .container {
    padding: 10px;
  }
}

@media (max-width: 340px) {
  html,
  body {
    font-size: 12px;
  }
}
