@import url(https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400&display=swap"); */
html {
  --main-color: #110a40;
  --contrast-color: #e9f1f2;
  --accent-color: #9b44f2;
  scroll-behavior: smooth;
  --second-accent-color: #4f5873;
}
* {
  box-sizing: border-box;
}
html,
body {
  overflow-x: hidden;
  font-family: "Jost", sans-serif;
  font-size: 19px;
}
.container {
  margin: 0 auto;
  padding: 0;
}
a,
a:hover {
  text-decoration: none;
}
h1,
h2,
h3 {
  text-transform: uppercase;
}
h2,
h3,
h4,
h5 {
  font-weight: bold;
  color: #e9f1f2;
  color: var(--contrast-color);
}
h2 {
  font-size: 1.8rem;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    visibility: visible;
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    visibility: visible;
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }
  100% {
    visibility: hidden;

    transform: translateX(100%);
  }
}

@keyframes move_wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }
  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}

.common__section .container {
  width: 1120px;
  margin: 0 auto;
  padding: 60px 0;
}

.container.move {
  animation-name: slideInLeft;
  animation-duration: 2s;
  animation-fill-mode: both;
}
.container.unmove {
  animation-name: slideInRight;
  animation-duration: 2s;
  animation-fill-mode: both;
}
@media (min-width: 1201px) and (max-width: 1365px) {
  .common__section .container {
    width: 1100px;
  }
}
@media (max-width: 1200px) {
  .container,
  .common__section .container {
    width: 100vw;
    max-width: 100vw;
    padding: 40px 10px;
  }
}
@media (max-width: 450px) {
  html,
  body {
    font-size: 14px;
  }
  .container,
  .common__section .container {
    padding: 10px;
  }
}
@media (max-height: 450px) {
  html,
  body {
    font-size: 14px;
  }
  .container,
  .common__section .container {
    padding: 10px;
  }
}

@media (max-width: 340px) {
  html,
  body {
    font-size: 12px;
  }
}

.bannerTop {
  width: 100vw;
  height: 100vh;
}
.waveWrapper {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  justify-content: center;
  background: url(/static/media/bgNew.2f37e538.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  z-index: 20;
}
.waveWrapperInner {
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: 100%;
  bottom: -1px;
  background-image: linear-gradient(
    to top,
    rgba(134, 55, 123, 0.4) 20%,
    rgba(39, 39, 60, 0.4) 80%
  );
}
.bgTop {
  z-index: 15;
  opacity: 0.5;
}
.bgMiddle {
  z-index: 10;
  opacity: 0.75;
}
.bgBottom {
  z-index: 5;
}
.wave {
  position: absolute;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
}
.waveTop {
  background-size: 50% 100px;
  background-image: url(/static/media/wave-top.5fe38509.png);
}
.waveAnimation .waveTop {
  animation: move-wave 3s;
  -webkit-animation: move-wave 3s;
  animation-delay: 1s;
}
.waveMiddle {
  background-size: 50% 120px;
  background-image: url(/static/media/wave-mid.f1866e4b.png);
}
.waveAnimation .waveMiddle {
  animation: move_wave 10s linear infinite;
}
.waveBottom {
  background-size: 50% 100px;
  background-image: url(/static/media/wave-bot.9e6b49b5.png);
}
.waveAnimation .waveBottom {
  animation: move_wave 15s linear infinite;
}
.banner .container {
  z-index: 21;
  color: var(--contrast-color);
  position: absolute;
  top: 40px;
  left: 100px;
  width: 40vw;
}
.banner .container.move {
  margin-left: 30vw;
}
.banner .container.unmove {
  margin-left: 0;
}
h1 {
  font-size: 2.5rem;
}
.bannerText {
  margin-top: 60px;
}
.banner h2 {
  margin: 40px 0;
}
.banner-descr {
  line-height: 1.5;
  font-size: 1.5rem;
}
.bannerLogo img {
  height: 90px;
}

@media (max-width: 1200px) {
  .bannerTop {
    height: 90vh;
  }
  .waveWrapper {
    width: 100%;
    height: 90vh;
    bottom: auto;
  }
  .banner .container {
    width: 100vw;
    max-width: 100vw;
    top: 60px;
    left: 20px;
  }
  .bannerLogo {
    max-width: 80vw;
  }
}
@media (max-width: 1024px) and (min-height: 1024px) {
  .bannerTop {
    height: 50vh;
  }
  .waveWrapper {
    height: 50vh;
  }
}
@media (max-width: 1024px) and (max-height: 660px) {
  .bannerLogo {
    max-width: 50vw;
  }
  .bannerTop {
    height: 100vh;
  }
  .waveWrapper {
    height: 100vh;
  }
}
@media (max-width: 800px) {
  .bannerTop {
    height: 70vh;
  }
  .waveWrapper {
    height: 70vh;
  }
  .banner h2 {
    margin: 30px 20px 30px 0px;
  }
  .banner-descr {
    margin-right: 20px;
  }
}
@media (max-width: 600px) {
  .bannerLogo img {
    height: 65px;
  }
}

@media (max-width: 450px) {
  .bannerTop {
    height: 100vh;
  }

  .waveWrapper {
    height: 100vh;
  }
  .bannerLogo {
    max-width: 100vw;
  }
  .banner .container {
    top: 50px;
    left: 0px;
  }
  .bannerText {
    margin-top: 20px;
  }
  .banner h2 {
    margin: 10px 0;
  }
  .banner-descr {
    margin-right: 0;
  }
}

@media (max-width: 450px) and (min-height: 730px) {
  .bannerTop {
    height: 80vh;
  }
  .bannerLogo img {
    height: 65px;
  }
  .waveWrapper {
    height: 80vh;
  }
}

@media (max-height: 450px) {
  .bannerTop {
    height: 100vh;
  }
  .bannerLogo img {
    height: 65px;
  }
  .waveWrapper {
    height: 100vh;
  }
  .banner .container {
    top: 50px;
    left: 0px;
  }
  .bannerText {
    margin-top: 20px;
  }
  .banner h2 {
    margin: 10px 0;
  }
  .banner-descr {
    margin-right: 0;
  }
}

@media (max-height: 380px) {
  h1 {
    font-size: 2rem;
  }
  .banner h2 {
    font-size: 1.5rem;
  }
  .banner-descr {
    font-size: 1.1rem;
  }
  .banner .container {
    top: 30px;
  }
  .bannerLogo {
    max-width: 40vw;
  }
}
@media (max-width: 359px) {
  .bannerLogo img {
    height: 50px;
  }
}

@media (max-height: 450px) and (max-width: 640px) {
  .bannerLogo img {
    height: 45px;
  }
}

@keyframes flipInY {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transition-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}
.howToStart {
  position: relative;
}
.howToStart .container.move {
  margin-left: 35vw;
}
.howToStart .container.unmove {
  margin-left: 0 auto;
}

.howToStart h2 {
  color: var(--main-color);
}
.howToStart .list {
  list-style: none;
  padding: 0;
  margin: 40px 0;
  display: flex;
  grid-column-gap: 20px;
  column-gap: 20px;
}

.howToStart.active .list li {
  background: var(--contrast-color);
  padding: 20px 10px;
  border-radius: 5px;
  border-top: 20px solid #44f2f2;
  box-shadow: 2px -2px 5px 0 rgba(0, 0, 0, 0.1),
    -2px -2px 5px 0 rgba(0, 0, 0, 0.1), 2px 2px 5px 0 rgba(0, 0, 0, 0.1),
    -2px 2px 5px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s all linear;
  animation-name: flipInY;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
  animation-duration: 1s;
  animation-fill-mode: both;
  width: 33%;
}

.howToStart.active .list li:nth-child(1) {
  animation-delay: 0.25s;
}
.howToStart.active .list li:nth-child(2) {
  animation-delay: 1s;
}
.howToStart.active .list li:nth-child(3) {
  animation-delay: 2s;
}

@media (max-width: 700px) {
  .howToStart .list {
    flex-wrap: wrap;
    grid-row-gap: 20px;
    row-gap: 20px;
    justify-content: space-around;
  }
  .howToStart.active .list li {
    width: 48%;
  }
}
@media (max-width: 450px) {
  .howToStart .container {
    padding-bottom: 50px;
  }
  .howToStart.active .list li {
    width: 100%;
  }
  .howToStart .list {
    margin: 20px 0;
  }
}

@media (max-height: 450px) {
  .howToStart .container {
    padding-bottom: 50px;
  }
  .howToStart.active .list li {
    width: 100%;
  }
  .howToStart .list {
    margin: 20px 0;
  }
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 60px;
  background: #9b44f2;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 21;
  cursor: pointer;
}
.menu .burgerImg {
  width: 50px;
  height: 50px;
}

.burgerImg.unrotate {
  animation-name: unrotate;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.burgerImg.rotate {
  animation-name: rotateIn;
  animation-duration: 1s;
  animation-fill-mode: both;
} 

/*animations frames*/
@keyframes rotateIn {
  0% {
    transform-origin: center;
    transform: rotate(0);
    opacity: 0;
  }
  100% {
    transform-origin: center;
    transform: rotate(90deg);
    opacity: 1;
  }
}
@keyframes unrotate {
  0% {
    transform-origin: center;
    transform: rotate(0, 0, 1, -100deg);
    opacity: 0;
  }
  100% {
    transform-origin: center;
    transform: none;
    opacity: 1;
  }
} 

@media (max-width: 1200px) {
  .menu {
    height: 50px;
    width: 100vw;
    justify-content: flex-end;
  }
  .burgerImg {
    margin-right: 20px;
  }
}

@media (max-width: 450px) {
  .menu {
    height: 40px;
  }
  .menu .burgerImg {
    width: 40px;
    height: 40px;
  }
  .burgerImg {
    margin-right: 10px;
  }
}
@media (max-height: 450px) {
  .menu {
    height: 40px;
  }
  .menu .burgerImg {
    width: 40px;
    height: 40px;
  }
  .burgerImg {
    margin-right: 10px;
  }
}
@media (max-height: 375px){
  .menu {
    height: 30px;
  }
  .menu .burgerImg {
    width: 30px;
    height: 30px;
  }

}

.nav {
  display: flex;
  flex-direction: column;
  z-index: 25;
  padding: 40px 20px;
  animation-name: slideInLeft;
  animation-duration: 2s;
  animation-fill-mode: both;
  position: absolute;
  background-image: linear-gradient(
    to bottom,
    #2d1973,
    #532aa2,
    #6732ba,
    #7d3ad2,
    #e9f1f2
  );
  height: 100vh;
  width: 30vw;
  position: fixed;
  top: 0;
  left: 0;
}
.nav.show {
  animation-name: slideInLeft;
  animation-duration: 2s;
  animation-fill-mode: both;
}
.nav.hidden {
  animation-name: slideOutLeft;
  animation-duration: 1.5s;
  animation-fill-mode: both;
}
.closeForm {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

.nav-link {
  width: 80%;
  color: var(--contrast-color);
  padding: 10px 20px;
}

.nav-link:hover {
  background: var(--contrast-color);
  color: var(--main-color);
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    visibility: hidden;
    transform: translateX(-100%);
  }
}

@media (max-width: 1200px) {
  .nav {
    width: 100vw;
    right: 0;
    max-width: 100vw;
  }
  .nav.show {
    animation-name: slideInRight;
    animation-duration: 2s;
    animation-fill-mode: both;
  }
  .nav.hidden {
    animation-name: slideOutRight;
    animation-duration: 1.5s;
    animation-fill-mode: both;
  }
  .nav-link{
    font-size: 1.5rem;
  }
}

@keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.second h2 {
  color: var(--main-color);
}
.second .container {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: 1fr;
  grid-column-gap: 40px;
  column-gap: 40px;
}
.second .imgBlock {
  position: relative;
}

.second .textImage {
  width: 90%;
  margin: 0 auto;
}
.second .image {
  position: absolute;
  top: 50px;
  left: 202px;
  width: 30%;
  animation: 20s linear 0s normal none infinite running rot;
}
.second .blocks {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.second .container.move {
  margin-left: 35vw;
}
.second .container.unmove {
  margin-left: 0 auto;
}

.second .imgCheckPhone,
.second .imgCheckEmail {
  width: 18px;
  position: absolute;
  bottom: 10px;
  right: 20px;
}
.second .imgCheckPhone {
  bottom: 16px;
}
.PhoneInputInput {
  padding-top: 5px;
  padding-bottom: 5px;
}
.PhoneInput--focus input {
  outline: 0 !important;
  outline-offset: 0 !important;
  border: 1px solid var(--second-accent-color);
}
.PhoneInput input:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  border: 1px solid var(--second-accent-color);
}
.second .form-phone,
.second .form-email {
  position: relative;
}
.contactForm label,
.contactForm input {
  font-size: 14px;
}
.contactForm label {
  font-weight: bold;
  color: var(--main-color);
}
.contactForm > div {
  margin-bottom: 15px;
}
.contactForm > div:last-child {
  margin-bottom: 0;
}
.contactForm > div:last-child {
  margin-top: 30px;
}
.contactForm .btn-primary {
  background: #5a30f2;
  border: none;
}
.contactForm .btn-primary:hover {
  background: var(--accent-color);
}

@media (max-width: 1200px) {
  .second .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    grid-row-gap: 60px;
    row-gap: 60px;
  }
  .second .blocks {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    row-gap: 10px;
  }
  .second .imgBlock {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .second .textImage {
    width: 70%;
  }
  .second .image {
    top: 70px;
    left: 39%;
    width: 22%;
  }
  .contactForm {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 20px;
    column-gap: 20px;
    grid-row-gap: 20px;
    row-gap: 20px;
  }
  .contactForm > div {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0;
  }
  .contactForm > div:last-child {
    margin-top: 0px;
  }
  .cF-button button {
    margin-bottom: 6px;
  }
}

@media (max-width: 800px) {
  .second .textImage {
    width: 100%;
  }
  .second .image {
    top: 80px;
    width: 30%;
    left: 35%;
  }
}
@media (max-width: 600px) {
  .second .image {
    top: 50px;
  }
}
@media (max-width: 450px) {
  .contactForm > div {
    width: 100%;
  }
  .second .image {
    width: 30%;
    left: 35%;
    top: 22%;
  }
}

@media (max-height: 450px) {
  .contactForm > div {
    width: 100%;
  }
  .second .image {
    width: 30%;
    left: 35%;
    top: 22%;
  }
}

@media (max-height: 450px) and (min-width: 750px) {
  .second .image {
    width: 25%;
    left: 38%;
    top: 16%;
  }
}

.tesimonials__external {
  position: relative;
  background: #a864edff;
  /* background-image: linear-gradient(
    to right bottom,
    #a961f1,
    #ad7ada,
    #cccdd2,
    #e9f1f2
  ); */
}

.carousel-control-prev {
  left: -60px;
}

.carousel-control-next {
  right: -60px;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: var(--main-color);
  padding: 15px;
  display: block;
  border-radius: 50%;
  background-size: 24px;
  background-position-x: 8px;
}
.tesimonials__external h5 {
  color: var(--main-color);
}
.testimonial-content p {
  border: 2px solid var(--main-color);
  border-radius: 10px;
  position: relative;
  left: -20px;
  bottom: -10px;
  background: var(--contrast-color);
}

.testimonial-content p:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid var(--main-color);
  transform: rotate(45deg);
  left: -11px;
  bottom: 13px;
  background: var(--contrast-color);
  border-right: none;
  border-top: none;
}
#testimonials .carousel.slide {
  width: 100%;
  padding: 35px;
}

#testimonials .carousel.slide p {
  font-style: italic;
}

#testimonials .user-avatar img {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 10px;
}

.tesimonials__external .waveNew {
  position: absolute;
  left: 0;
  width: 200%;
  height: 60px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation-name: move-forever;
  animation-duration: 12s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}
.tesimonials__external .waveTop {
  top: -20px;
  z-index: 2;
  background: url(/static/media/wt.d001a014.svg);
  animation: move_wave 10s linear infinite;
}
.tesimonials__external .waveMid {
  top: -40px;
  z-index: 1;
  background: url(/static/media/wm.dd025888.svg);
  animation: move-wave 3s;
  -webkit-animation: move-wave 3s;
  animation-delay: 1s;
}
.tesimonials__external .waveBot {
  top: -60px;
  z-index: 0;
  background: url(/static/media/wb.bcbb07df.svg);
  animation: move_wave 15s linear infinite;
}
.tesimonials__external .container.move {
  margin-left: 35vw;
}
.tesimonials__external .container.unmove {
  margin-left: 0 auto;
}
@media screen and (max-width: 1200px) {
  .slide-inner .user-avatar {
    display: flex;
    flex-flow: column;
    place-content: flex-end;
    justify-content: center;
    align-items: center;
  }
  .testimonial-content p:after {
    bottom: 50%;
  }
}
@media screen and (max-width: 992px) {
  .testimonial-content p:after {
    display: none;
  }
  #testimonials .carousel-control-prev {
    left: -10px;
  }

  #testimonials .carousel-control-next {
    right: -10px;
  }

  #testimonials .user-avatar img {
    width: 100px;
  }
  .testimonial-content p {
    left: 0;
  }
  #testimonials .carousel-control-prev,
  #testimonials .carousel-control-next {
    top: -200px;
  }
}
@media (max-width: 767px) {
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background-size: 18px;
    background-position-x: 6px;
  }
}
@media screen and (max-height: 450px) {
  #testimonials .user-avatar img {
    width: 150px;
  }
  .testimonial-content p {
    left: 0;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background-size: 18px;
    background-position-x: 6px;
  }
}

@media screen and (max-width: 450px) {
  .tesimonials__external .container {
    padding-top: 40px;
  }
}
@media screen and (max-height: 450px) {
  .tesimonials__external .container {
    padding-top: 40px;
  }
}

@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.whywe {
  background: linear-gradient(
    to right bottom,
    #d9b8f9,
    #d6baef,
    #d2bce5,
    #cebddc,
    #cabfd2,
    #c9c4d3,
    #c9c9d3,
    #cccdd2,
    #d3d6db,
    #dadfe3,
    #e1e8eb,
    #e9f1f2
  );
}
.whywe .container.move {
  margin-left: 35vw;
}
.whywe .container.unmove {
  margin-left: 0 auto;
}
.whywe h2 {
  color: var(--main-color);
}
.whywe .list {
  list-style: none;
  padding: 0;
  margin: 40px 0;
}
.whywe.active .list li {
  background: var(--contrast-color);
  padding: 7px 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  border-left: 20px solid #44f2f2;
  box-shadow: 2px -2px 5px 0 rgba(0, 0, 0, 0.1),
    -2px -2px 5px 0 rgba(0, 0, 0, 0.1), 2px 2px 5px 0 rgba(0, 0, 0, 0.1),
    -2px 2px 5px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s all linear;
  animation-name: pulse;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.whywe.whywe.active .list li:nth-child(1) {
  animation-delay: 0.25s;
}
.whywe.whywe.active .list li:nth-child(2) {
  animation-delay: 1s;
}
.whywe.whywe.active .list li:nth-child(3) {
  animation-delay: 2s;
}
.whywe.whywe.active .list li:nth-child(4) {
  animation-delay: 3s;
}

@media (max-width: 450px) {
  .whywe .list {
    margin: 20px 0;
  }
}

@media (max-height: 450px) {
  .whywe .list {
    margin: 20px 0;
  }
}

.whatsapp-icon, .tg-icon {
  position: fixed;
  bottom: 80px;
  left: 50px;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  background-color: #0bb10b;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 50000;
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;  
}

.whatsapp-icon:hover{ 
    background-color: #20ad20;
    animation-name: unset;
}

.tg-icon:hover {
    background-color: #3a87aa;
}

.whatsapp-icon:hover, .tg-icon:hover {
  opacity: 0.8;
}

.whatsapp-icon svg, .tg-icon svg {
  width: 40px;
  height: 40px;
  fill: #fff;
}

.tg-icon {
    bottom: 150px;
    background: #03a9f4;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg)scale(1);
  }
  50% {
    transform: rotate(-20deg)scale(1.2);
  }
  100% {
    transform: rotate(0deg)scale(1);
  }
}
